<template lang="html">
  <h4 class="dlg-header">
    <slot />
  </h4>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.dlg-header {
  font-weight: bold;

  &:not(:first-child) {
    margin-top: 1em;
  }
}
</style>
