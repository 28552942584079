import axios from 'axios'

import {get, set} from 'lodash'

export default {
  data () {
    return {
      loading: true,
      data: null
    }
  },
  computed: {
    api () {
      return axios.create({
        baseURL: this.apiUrl
      })
    },
    manualCompleteSections () {
      return {}
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        this.data = (await this.api.get()).data
        this.data.finished = {...this.manualCompleteSections, ...this.data.finished}
      } catch (error) {
        console.error(error)
        // TODO error state display
      } finally {
        this.loading = false
      }
    },
    async update ({name, value}) {
      const oldValue = get(this.data, name)

      if (value === oldValue) return
      if (typeof value !== 'string' && isNaN(value)) return

      set(this.data, name, value)

      await this.$nextTick()

      if (this.fieldStates && this.fieldStates[name] === 'error') return

      try {
        if (value || value === 0) {
          await this.api.put(`${name}/${value}`)
        } else {
          await this.api.delete(`${name}`)
        }
      } catch (error) {
        console.error(error)
        set(this.data, name, oldValue)
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadData()
    })
  }
}
