<template lang="html">
  <LoadingWrapper :loading="loading">
    <DlgHeader>
      <IxRes>dlgCert.social.employeeMatters.headers.applicableToOrganization</IxRes>
    </DlgHeader>
    <CheckboxLine :data="data" name="writtenEmploymentContracts" @input="update" />
    <CheckboxLine :data="data" name="clearJobProfiles" @input="update" />
    <CheckboxLine :data="data" name="regularFeedback" @input="update" />
    <CheckboxLine :data="data" name="regularMeetings" @input="update" />
    <CheckboxLine :data="data" name="contributionToCorporateCulture" @input="update" />
    <CheckboxLine :data="data" name="teamActivities" @input="update" />
    <CheckboxLine :data="data" name="nonMonetaryBenefits" @input="update" />
    <CheckboxLine :data="data" name="behaviouralGuidelines" @input="update" />
  </LoadingWrapper>
</template>

<script>
import LoadingWrapper from './shared/LoadingWrapper'

import CheckboxLine from './elements/CheckboxLine'
import DlgHeader from './elements/DlgHeader'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    CheckboxLine,
    DlgHeader,
    LoadingWrapper
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.$route.params.year}/employee-matters`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
