<template lang="html">
  <div class="checkbox-line" :class="{locked}" :title="translatedTitle">
    <FontAwesomeIcon v-if="locked" :icon="icons.locked" class="lock-icon" />
    <BsCheckbox v-model="model" :disabled="locked">
      <slot>
        <DlgInputDescription :name="name" />
      </slot>
    </BsCheckbox>
  </div>
</template>

<script>
import {get} from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'

import BsCheckbox from '@components/bootstrap/BsCheckbox'

import DlgInputDescription from './DlgInputDescription'

import translate from 'src/js/i18n/translate'

export default {
  components: {
    FontAwesomeIcon,
    BsCheckbox,
    DlgInputDescription
  },
  props: {
    data: Object,
    name: String
  },
  computed: {
    icons () {
      return {
        locked: faLock
      }
    },
    locked () {
      return !this.$listeners.input
    },
    model: {
      get () {
        return get(this.data, this.name)
      },
      set (value) {
        this.$emit('input', {name: this.name, value})
      }
    },
    translatedTitle () {
      return this.locked ? translate('dlgCert.info.ask') : null
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-line {
  display: flex;
  align-items: center;
  position: relative;
}

.lock-icon {
  color: gray;
  font-size: 0.8em;
  position: absolute;
  left: -15px;
}

::v-deep .bs-checkbox > .checkbox {
  margin: 0;
}
</style>
